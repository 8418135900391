<!--  -->
<template>
  <div class="content">
       <top-title>
      <span slot="left">消息中心</span>
      <span slot="right">消息中心</span>
    </top-title>
    <div class="data">
        <!--  @tab-click="handleClick" -->
         <el-tabs v-model="activeName">
        <el-tab-pane label="未读消息" name="first">
        <el-table
            :show-header='false'
            :data="tableData"
            style="width: 100%;margin-bottom:30px">
            <el-table-column
                >
                <template slot-scope="scope">
                    <el-link href="/message/info" :underline="false" style="color:#20a0ff">{{scope.row.content}}</el-link>
                </template>
            </el-table-column>
            <el-table-column
                prop="date"
                width="180">
            </el-table-column>
            <el-table-column
                 width="180"
                fixed="right"
                 >
                <template>
                    <el-button  size="small">标为已读</el-button>
                </template>
            </el-table-column>
        </el-table>
         <el-button  size="small" type="primary">全部标为已读</el-button>
        </el-tab-pane>
        <el-tab-pane label="已读消息" name="second">
            <el-table
            :show-header='false'
            :data="tableData"
            style="width: 100%;margin-bottom:30px">
            <el-table-column
           
                >
                <template slot-scope="scope">
                    <el-link href="/message/info" :underline="false" style="color:#20a0ff">{{scope.row.content}}</el-link>
                </template>
            </el-table-column>
            <el-table-column
                prop="date"
                width="180">
            </el-table-column>
            <el-table-column
                 width="180"
                fixed="right"
                 >
                <template>
                    <el-button  type="danger"  size="small">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-button  type="danger"  size="small">删除全部</el-button>
        </el-tab-pane>
    </el-tabs>
    </div>
  </div>
</template>

<script>
import TopTitle from "@/components/common/top-title";
export default {
  data () {
    return {
        activeName:'first',
        tableData: [{
            date: '2020-05-02',
            content: '【系统通知】该系统将于今晚凌晨2点到5点进行升级维护',
          }, {
            date: '2020-05-04',
            content: '【系统通知】该系统将于今晚凌晨2点到5点进行升级维护',
          }, {
            date: '2020-05-01',
            content: '【系统通知】该系统将于今晚凌晨2点到5点进行升级维护',
          }, {
            date: '2020-05-03',
            content: '【系统通知】该系统将于今晚凌晨2点到5点进行升级维护',
          }]
    };
  },

  components: {
    TopTitle,
  },

  computed: {},

  methods: {}
}

</script>
<style lang='scss' scoped>
.data{
    padding: 20px 20px 40px;
    background: #fff;
  
}
</style>